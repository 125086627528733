<template>
  <div>  
    <v-row dense>
      <component v-for="(visual, index) in visuals" :key="visual.id" :is="visual.component" :visual="visual" @remove="remove(index)" @saved="changes => saved(visual, changes)"></component>
    </v-row>

    <visualModal btnClass="mt-10" icon="mdi-plus-circle" :fab="true" @added="added"></visualModal>
  </div>
</template>

<script>
import visualModal from '../components/Visual_Modal.vue'

export default {
  components: { visualModal },
  data: () => ({
    visuals: []
  }),
  mounted() {
    this.axios.get(this.REST_URL + "/visuals")
    .then(response => {
      for(var i = 0; i < response.data.length; i++){
        const device = response.data[i].bus_device || response.data[i].ip_device || response.data[i].modbus_tcp_device
        if(response.data[i].function && response.data[i].function.type && response.data[i].function.type.component) {
          response.data[i].component = ((name) => () => import('../components/types/' + name + '_visual.vue'))(response.data[i].function.type.component);
        }else if(device && device.device && device.device.component){
          response.data[i].component = ((name) => () => import('../components/templates/' + name + '.vue'))(device.device.component);
        }
        
        this.visuals.push(response.data[i]);
      }
      this.visuals = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  methods: {
    remove(index) {
      console.log(index);
      this.$delete(this.visuals, index);
    },
    added(visual) {
      console.log(visual);
      visual.component = ((name) => () => import('../components/types/' + name + '_visual.vue'))(visual.function.type.component);
      this.visuals.push(visual);
    },
    saved(visual, newVisual) {
      Object.assign(visual, newVisual);
    }
  }
}
</script>
