import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/stats',
    name: 'Stats',
    component: () => import('../views/Stats.vue')
  },
  {
    path: '/logic',
    name: 'Logic',
    component: () => import('../views/Logic.vue')
  },
  {
    path: '/protocol',
    name: 'Protocol',
    component: () => import('../views/Protocol.vue')
  },
  {
    path: '/bus',
    name: 'Bus',
    component: () => import('../views/Bus.vue')
  },
  {
    path: '/ip',
    name: 'IP',
    component: () => import('../views/IP.vue')
  },
  {
    path: '/modbus_tcp',
    name: 'ModbusTCP',
    component: () => import('../views/ModbusTCP.vue')
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('../views/Message.vue')
  },
  {
    path: '/cameras',
    name: 'Cameras',
    component: () => import('../views/Cameras.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
