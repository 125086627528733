<template>
  <v-app style="background: var(--v-background-base)">
    <v-app-bar app flat color="primary" :color="'primary ' + ($vuetify.theme.dark ? 'darken-4' : 'darken-1')" dark>
      <template v-slot:img="{ props }">
          <v-img v-bind="props" :gradient="'to top right, ' + ($vuetify.theme.dark ? 'var(--v-primary-darken4), var(--v-primary-lighten1)' : 'var(--v-primary-darken1), var(--v-primary-lighten2)')"></v-img>
      </template>

      <v-layout class="d-flex align-center" fill-height>
        <img src="@/assets/smoover.png" height="100%"><img>
      </v-layout>
      <v-spacer></v-spacer>
      <v-icon @click.stop="showNavigation = !showNavigation" class="d-md-none">mdi-menu</v-icon>
    </v-app-bar>

    <v-navigation-drawer app disable-resize-watcher disable-route-watcher v-model="showNavigation">
      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-switch v-model="$vuetify.theme.dark" label="Dunkelmodus"></v-switch>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-layout fill-height>
        <v-container style="width: auto" class="d-none d-md-flex">
          <v-card width="256" height="100%" class="rounded-xl">
            <v-navigation-drawer floating permanent>
              <v-list dense nav rounded>
                <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-switch v-model="$vuetify.theme.dark" label="Dunkelmodus"></v-switch>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </v-container>
        <v-container fluid class="pl-md-0">
          <router-view/>
        </v-container>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
sessionStorage.setItem('showNavigation', sessionStorage.getItem('showNavigation') || false);


if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  localStorage.setItem('darkmode', localStorage.getItem('darkmode') || true);
}else{
  localStorage.setItem('darkmode', localStorage.getItem('darkmode') || false);
}

export default {
  name: 'App',

  data: () => ({
    showNavigation: sessionStorage.getItem('showNavigation') == 'true',
    items: [
      { title: 'Dashboard', icon: 'mdi-collage', to: {name: 'Dashboard'} },
      { title: 'Cameras', icon: 'mdi-cctv', to: {name: 'Cameras'} },
      { title: 'Stats', icon: 'mdi-equalizer', to: {name: 'Stats'} },
      { title: 'Logic', icon: 'mdi-camera-control', to: {name: 'Logic'} },
      { title: 'Protokoll', icon: 'mdi-format-list-group ', to: {name: 'Protocol'} },
      { title: 'Bus', icon: 'mdi-network', to: {name: 'Bus'} },
      { title: 'IP', icon: 'mdi-ip-network', to: {name: 'IP'} },
      { title: 'ModbusTCP', icon: 'mdi-ip-network-outline', to: {name: 'ModbusTCP'} },
      { title: 'Nachricht', icon: 'mdi-forum', to: {name: 'Message'} },
    ]
  }),
  created() {
    this.$vuetify.theme.dark = localStorage.getItem('darkmode') == 'true'
  },
  watch: {
    showNavigation(newValue) {
      sessionStorage.setItem('showNavigation', newValue);
    },
    '$vuetify.theme.dark': (newValue) => {
      localStorage.setItem('darkmode', newValue);
    }
  }
};
</script>

<style>
.v-card {
  overflow: hidden !important;
}

.v-card__title {
  word-break: break-word;
}
</style>