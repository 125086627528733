import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        options: {
            customProperties: true
        },
        dark: false,
        themes: {
            light: {
                background: '#f0f0f0',
                primary: '#4accde' //'#e91e63'
            },
            dark: {
                background: '#000',
                primary: '#4accde'
                
            }
        }
    }
});
