<template>
  <v-dialog v-model="visible" persistent max-width="600px">
    <template v-slot:activator="{ attrs }">
      <template v-if="fab">
        <v-btn fab absolute top right @click.stop="openModal" :class="btnClass">
          <v-icon :color="color" v-bind="attrs">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-icon :color="color" v-bind="attrs" small right @click.stop="openModal" :class="btnClass">{{ icon }}</v-icon>
      </template>
    </template>

    <v-card>
      <v-card-title>
        <span v-if="item" class="text-h5">Edit Virtual IO</span>
        <span v-else class="text-h5">Create Virtual IO</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="8">
              <v-text-field label="Title" v-model="title"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select v-model="customIcon" :items="icons" item-text="title" label="Custom icon" return-object single-line :loading="state == 1">
                <template v-slot:item="{ item }">
                  <v-icon left>{{ item.state2 }}</v-icon>{{ item.title }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-icon left>{{ item.state2 }}</v-icon>{{ item.title }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select v-model="device" :items="devices" item-text="title2" label="Device" return-object single-line :loading="state == 1" @input="deviceChanged"></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select v-model="functionCode" :items="functionCodes" item-text="title" label="Function code" return-object single-line :loading="state == 2" @input="functionCodeChanged" v-if="functionCodes.length > 0"></v-select>
            </v-col>

            <template v-if="functionCode">
              <v-col cols="1">
                <v-icon x-large>{{ functionCode.type.icon.state2 }}</v-icon>
              </v-col>
              <v-col cols="10">
                <v-subheader>{{ functionCode.type.title }}</v-subheader>
              </v-col>

              <component :is="configuration_component" :item="item" :payload="payload" v-on="$listeners"></component>
            </template>
            
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="item != null" color="red darken-1" text @click="remove">Remove</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="close">Abort</v-btn>
        <v-btn v-if="item" color="green" text @click="save" :disabled="title == '' || device == null || (functionCodes.length > 0 && functionCode == null)">Save</v-btn>
        <v-btn v-else color="green" text @click="add" :disabled="title == '' || device == null || (functionCodes.length > 0 && functionCode == null)">Add</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  props: ['item', 'color', 'icon', 'btnClass', 'fab', 'payload'],
  data:() => ({
    visible: false,
    state: 0,
    title: "",
    devices: [],
    device: null,
    functionCodes: [],
    functionCode: null,
    modbusRegisters: [],
    modbusRegister: null,
    data: "",
    configuration_component: null,
    icons: [],
    customIcon: null
  }),
  methods: {
    close(emitCloseEvent) {
      this.visible = false;
      this.state = 0;
      this.title = "";
      this.devices = [];
      this.device = null;
      this.functionCodes = [];
      this.functionCode = null;
      this.customIcon = null;
      this.data = "";
      if(typeof emitCloseEvent != "boolean" || emitCloseEvent) this.$emit("closed");
    },
    add() {
      this.axios.post(this.REST_URL + "/visuals", { title: this.title, iconId: this.customIcon ? this.customIcon.id : 0, serialNumber: this.device.serialNumber, functionCode: this.functionCode ? this.functionCode.functionCode : 0 })
      .then(response => {
        this.$emit('added', Object.assign(response.data, { gateway: this.device.gateway, function: this.functionCode, icon: this.customIcon }));
        this.close(false);
      })
      .catch(error => {
        console.log(error);
        this.state = 5;
      });
    },
    save() {
      this.axios.put(this.REST_URL + "/visuals/" + this.item.id, { title: this.title, iconId: this.customIcon ? this.customIcon.id : 0, serialNumber: this.device.serialNumber, functionCode: this.functionCode ? this.functionCode.functionCode : 0 })
      .then(() => {
        this.$emit('saved', { title: this.title, iconId: this.customIcon ? this.customIcon.id : 0, serialNumber: this.device.serialNumber, functionCode: this.functionCode ? this.functionCode.functionCode : 0, gateway: this.device.gateway, function: this.functionCode, icon: this.customIcon });
        this.close(false);
      })
      .catch(error => {
        console.log(error);
        this.state = 5;
      });
    },
    remove() {
      this.axios.delete(this.REST_URL + "/visuals/" + this.item.id)
      .then(() => {
        this.$emit('remove');
        this.close(false);
      })
      .catch(error => {
        console.log(error);
        this.state = 5;
      });
    },
    openModal() {
      console.log(this.payload);
      this.title = "";      
      this.data = "";
      this.devices = [];
      this.device = null;
      this.functionCodes = [];
      this.functionCode = null;
      this.customIcon = null;

      if(this.item){
        this.title = this.item.title;      
        this.data = this.item.data;
      }

      this.state = 1;
      this.visible = true;

      this.axios.get(this.REST_URL + "/icons")
      .then(response => {
        this.icons = response.data;
        if(this.item) this.customIcon = this.item.icon;

        this.axios.get(this.REST_URL + "/ip_devices")
        .then(response => {
          for(var i = 0; i < response.data.length; i++) {
            response.data[i].title2 = response.data[i].alias || response.data[i].device.title;
            this.devices.push(response.data[i]);
          }

          this.axios.get(this.REST_URL + "/bus_devices")
          .then(response => {
            for(var i = 0; i < response.data.length; i++) {
              response.data[i].title2 = response.data[i].alias || response.data[i].device.title;
              this.devices.push(response.data[i]);
            }

            this.axios.get(this.REST_URL + "/modbus_tcp_devices")
            .then(response => {
              for(var i = 0; i < response.data.length; i++) {
                response.data[i].title2 = response.data[i].alias || response.data[i].device.title;
                this.devices.push(response.data[i]);
              }

              if(this.item){
                this.device = this.item.bus_device || this.item.ip_device;
                if(this.device){
                  this.device.title2 = this.device.alias || this.device.device.title;
                  this.deviceChanged(this.device, this.item.function);
                }
              }

              this.state = 0;
            })
            .catch(error => {
              console.log(error);
              this.state = -1;
            });
          })
          .catch(error => {
            console.log(error);
            this.state = -1;
          });
        })
        .catch(error => {
          console.log(error);
          this.state = -1;
        });
      })
      .catch(error => {
        console.log(error);
        this.state = -1;
      });
    },
    deviceChanged(newDevice, newFunctionCode) {
      this.state = 2;
      this.functionCode = null;

      this.axios.get(this.REST_URL + "/devices/" + this.device.deviceId)
      .then(response => {
        this.functionCodes = response.data.devices_functions;

        if(newFunctionCode){
          this.functionCode = newFunctionCode;
          this.functionCodeChanged();
        }

        this.state = 0;
      })
      .catch(error => {
        console.log(error);
        this.state = -1;
      });
    },
    functionCodeChanged() {
      if(this.functionCode && this.functionCode.type) this.configuration_component = () => import('./types/' + this.functionCode.type.component + '_configuration.vue');
      else this.configuration_component = null;
    }
  }
};
</script>